import React from 'react'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { Button } from '@deal/components'
import { toast } from 'react-toastify'
import { useStartExpertShiftMutation } from '../../startExpertShift.generated'
import { ExpertPresenceStatus } from '#src/generated/types'
import styles from './styles.css'

const StartCATShiftButton: React.FC = () => {
  const businessUser = useBusinessUser()
  const [startExpertShift] = useStartExpertShiftMutation({
    variables: {
      id: businessUser.id
    },
    onCompleted: data => {
      if (data.startExpertShift.error) {
        toast.error(data.startExpertShift.error)
      } else if (
        data.startExpertShift.businessUser &&
        data.startExpertShift.businessUser.expertPresence.status === ExpertPresenceStatus.ON_SHIFT
      ) {
        toast.success('CAT started')
      }
    }
  })

  return (
    <Button size="small" className={styles.button} onClick={() => startExpertShift()}>
      Start CAT
    </Button>
  )
}

export default StartCATShiftButton
