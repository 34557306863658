import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { Popover } from '@deal/components'
import ArrowUpIcon from './arrow-up.svg'
import OptionsIcon from './three-dots.svg'
import styles from './styles.css'

interface NavSectionWithCollapsibleContentProps {
  sectionTitle: string
  contentVisible?: boolean
  optionsContent?: React.ReactNode
  optionsVisible?: boolean
  onOptionsIconClick?: () => void
  onOptionsClose?: () => void
}

const NavSectionWithCollapsibleContent: React.FC<React.PropsWithChildren<
  NavSectionWithCollapsibleContentProps
>> = ({
  sectionTitle,
  contentVisible,
  optionsContent,
  optionsVisible,
  onOptionsIconClick,
  onOptionsClose,
  children
}) => {
  const [isContentVisible, setIsContentVisible] = useState(false)

  useEffect(() => {
    contentVisible && setIsContentVisible(true)
  }, [contentVisible])
  const navSectionId = 'nav-section-' + sectionTitle.replace(/\s/g, '-').toLowerCase()

  return (
    <div className={styles.container}>
      <div className={styles.topLine} onClick={() => setIsContentVisible(!isContentVisible)}>
        <div className={classnames(styles.arrowIcon, { [styles.rotate]: isContentVisible })}>
          <ArrowUpIcon />
        </div>
        <div className={styles.header} id={navSectionId}>
          {sectionTitle}
        </div>
        {optionsContent && (
          <Popover
            contentLabel="options"
            visible={optionsVisible}
            onRequestClose={onOptionsClose}
            content={optionsContent}
            wrapperClassName={styles.optionsContent}
          >
            <div
              onClick={e => {
                e.stopPropagation()
                onOptionsIconClick?.()
              }}
            >
              <OptionsIcon />
            </div>
          </Popover>
        )}
      </div>
      {isContentVisible && <div className={styles.children}>{children}</div>}
    </div>
  )
}

export default NavSectionWithCollapsibleContent
