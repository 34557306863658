import React from 'react'
import { useHover } from 'react-aria'
import classNames from 'classnames'
import { Link, useLocation } from '@deal/router'
import { Text, TextProps } from '@deal/bluxome'
import { JiraTicketForJiraTicketLinkFragment } from './JiraTicketLink.generated'
import styles from './styles.css'

interface JiraTicketLinkProps {
  ticket: JiraTicketForJiraTicketLinkFragment
  /** @default base */
  size?: 'small' | 'base'
  /** @default neutrals-600 */
  color?: TextProps['color']
  children?: (props: { isHovered: boolean }) => React.ReactNode
}

export const JiraTicketLink: React.FC<JiraTicketLinkProps> = ({
  ticket,
  size = 'base',
  color = 'neutrals-600',
  children
}) => {
  const location = useLocation()
  const { hoverProps, isHovered } = useHover({})
  const targetPathname = `/help-center/ticket/${ticket.key}`
  const isDisabled = location.pathname === targetPathname

  return (
    <Link
      to={targetPathname}
      className={classNames(styles.ticketLink, { [styles.disabled]: isDisabled })}
      onClick={e => {
        if (isDisabled) e.preventDefault()
      }}
      {...hoverProps}
    >
      {children ? (
        <>{children({ isHovered })}</>
      ) : (
        <Text style={isHovered ? `${size}-underline` : size} color={color}>
          {ticket.key}
        </Text>
      )}
    </Link>
  )
}
