import React, { useContext, useEffect, useState } from 'react'
import useBreakpointWrapper from '#src/app/hooks/useBreakpoint'
import { LeadFragment } from '../../components/LeadConversation/LeadConversation.generated'

export enum Panel {
  Cart,
  CuratedItems,
  CuratedOrders,
  LimitedExperiences,
  Needs,
  Notes,
  OrderHistory,
  RatedItems,
  RecentlyViewedPublisherPages,
  RecentlyViewedSellables,
  RequestDetails,
  SellableFeedback,
  SellableRecommendations,
  EngagementPay,
  CS,
  ConversationSummary
}

// Context
type LeadDetailsContext = {
  lead: LeadFragment | undefined
  isLeadDetailsVisible: boolean
  setIsLeadDetailsVisible: React.Dispatch<React.SetStateAction<boolean>>
  setLead: (lead: LeadFragment | undefined) => void
  activePanels: Set<Panel>
  closePanel: (panel: Panel) => void
  openPanel: (panel: Panel) => void
  togglePanel: (panel: Panel) => void
}

const LeadDetailsContext = React.createContext<LeadDetailsContext>({
  lead: undefined,
  isLeadDetailsVisible: false,
  setIsLeadDetailsVisible: () => {},
  setLead: () => {},
  activePanels: new Set(),
  closePanel: () => {},
  openPanel: () => {},
  togglePanel: () => {}
})

// Consumer
const LeadDetailsConsumer = LeadDetailsContext.Consumer

// Provider
const LeadDetailsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const isWiderThanMd = useBreakpointWrapper('md')
  const [isLeadDetailsVisible, setIsLeadDetailsVisible] = useState(isWiderThanMd)
  const [activePanels, setActivePanels] = useState<Set<Panel>>(
    new Set([Panel.RequestDetails, Panel.EngagementPay])
  )
  const [lead, setLead] = useState<LeadFragment | undefined>(undefined)

  useEffect(() => {
    // Reset the visibility of the lead details when the screen is wider than md
    if (isWiderThanMd) {
      setIsLeadDetailsVisible(true)
    }
  }, [isWiderThanMd])

  const value = {
    lead: lead,
    isLeadDetailsVisible: isLeadDetailsVisible,
    setIsLeadDetailsVisible: setIsLeadDetailsVisible,
    setLead: setLead,
    activePanels,
    openPanel: (panel: Panel) => setActivePanels(new Set(activePanels.add(panel))),
    closePanel: (panel: Panel) => {
      const setCopy = new Set(activePanels)
      setCopy.delete(panel)
      setActivePanels(setCopy)
    },
    togglePanel: (panel: Panel) => {
      if (activePanels.has(panel)) {
        const setCopy = new Set(activePanels)
        setCopy.delete(panel)
        setActivePanels(setCopy)
      } else {
        setActivePanels(new Set(activePanels.add(panel)))
      }
    }
  }

  return <LeadDetailsContext.Provider value={value}>{children}</LeadDetailsContext.Provider>
}

const useLeadDetailsContext = () => {
  const leadDetailsContext = useContext(LeadDetailsContext)

  if (!leadDetailsContext) {
    throw new Error('Cannot access LeadDetailsContext outside the provider')
  }

  return leadDetailsContext
}

export { LeadDetailsContext, LeadDetailsConsumer, LeadDetailsProvider, useLeadDetailsContext }
