import { useEffect } from 'react'
import { useSetOnlineNudge } from './useSetOnlineNudge'

export const useShowAppcuesOnlineNudge = () => {
  const { shouldFireSetOnlineNudge, schedulePotentialSetOnlineNudge } = useSetOnlineNudge()

  useEffect(() => {
    // Fire after 5 minutes
    shouldFireSetOnlineNudge && schedulePotentialSetOnlineNudge(1000 * 60 * 5)
  }, [shouldFireSetOnlineNudge])
}
