// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { LeadSnoozeForIsFollowUpSetFragmentDoc } from '../../../../utilities/snooze/isFollowUpSet.generated';
import { LeadSnoozeForIsReminderSetFragmentDoc } from '../../../../utilities/snooze/isRemidnerSet.generated';
import { LeadForReminderFragmentDoc } from './Reminder/Reminder.generated';
import { LeadForFollowUpFragmentDoc, SnoozeStepForFollowUpFragmentDoc } from './FollowUp/FollowUp.generated';
import { SnoozeStepForSnoozeStepFragmentDoc } from './SnoozeStep/SnoozeStep.generated';
import { MediaFileFragmentDoc } from '../../../../fragments/mediaFile.generated';
import { SnoozeStepForIsInvalidSnoozeStepFragmentDoc } from '../../../../utilities/snooze/isInvalidSnoozeStep.generated';
import { LeadForConversationSummaryButtonFragmentDoc } from '../../../../components/ConversationSummaryButton/ConversationSummaryButton.generated';
export type LeadForSnoozeFormFragment = { __typename: 'Lead', id: any, conversationSummaryEligible: boolean, snooze?: { __typename: 'LeadSnooze', note?: string | null, isAiGenerated: boolean, id?: any | null, active: boolean, wakeupTime: any, leadActionAfterAllSnoozeSteps?: Types.LeadActionAfterAllSnoozeSteps | null, snoozeSteps?: Array<{ __typename: 'SnoozeStep', active: boolean, message?: string | null, messageSourceId?: any | null, isMessageSourceSuggestedMessage: boolean, wakeupTime: any, title?: string | null, messageSentAt?: any | null, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null }> | null } | null };

export const LeadForSnoozeFormFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForSnoozeForm"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"snooze"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"note"}},{"kind":"Field","name":{"kind":"Name","value":"snoozeSteps"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"active"}}]}},{"kind":"Field","name":{"kind":"Name","value":"isAiGenerated"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadSnoozeForIsFollowUpSet"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadSnoozeForIsReminderSet"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForReminder"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForFollowUp"}}]}}]} as unknown as DocumentNode;