import { useExperiment } from '@deal/experiment-js'
import { useUserAgentContext } from '#src/app/context/UserAgent'
import { isMobileNativeOrBrowser } from '#src/app/services/mobile'

const useLeadTabsExperiment = () => {
  const userAgent = useUserAgentContext()

  const { result } = useExperiment({
    experiment: 'biz-app-lead-tabs',
    defaultTreatment: 'control',
    options: {
      skip: isMobileNativeOrBrowser(userAgent)
    }
  })

  return result !== 'control'
}

export default useLeadTabsExperiment
