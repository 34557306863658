import useActionThrottle from '#src/app/hooks/useActionThrottle'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import React from 'react'
import { useNewAiReEngagementMessageSubscription } from './NewAiReEngagementMessage.generated'

const ONE_HOUR_IN_MILLIS = 1000 * 60 * 60

const NewAiReEngagementMessageSubscription: React.FC<React.PropsWithChildren<unknown>> = () => {
  const businessUser = useBusinessUser()
  // Trigger the appcues flow, experts haven't seen it in a while.
  const { isAllowedToRun: shouldShowAppcues, markAsRun } = useActionThrottle(
    'newAiReEngagementMessage',
    ONE_HOUR_IN_MILLIS
  )
  useNewAiReEngagementMessageSubscription({
    variables: { expertId: businessUser.id },
    onData: ({ data: { data } }) => {
      if (shouldShowAppcues && window) {
        window.Appcues?.track('Lead re-engagement scheduled', {
          leadId: data?.leadReEngagementScheduled.id
        })
        markAsRun()
      }
    }
  })
  return null
}

export default NewAiReEngagementMessageSubscription
