// @ts-nocheck
import type * as Types from '../../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { LeadForConversationSummaryButtonFragmentDoc } from '../../../../../components/ConversationSummaryButton/ConversationSummaryButton.generated';
import { SnoozeStepForSnoozeStepFragmentDoc } from '../SnoozeStep/SnoozeStep.generated';
import { MediaFileFragmentDoc } from '../../../../../fragments/mediaFile.generated';
import { SnoozeStepForIsInvalidSnoozeStepFragmentDoc } from '../../../../../utilities/snooze/isInvalidSnoozeStep.generated';
import { SuggestedMessageRevisionForSuggestedMessageDisplayFragmentDoc } from '../../../../../components/SuggestedMessageDisplay/SuggestedMessageDisplay.generated';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LeadForFollowUpFragment = { __typename: 'Lead', id: any, conversationSummaryEligible: boolean, snooze?: { __typename: 'LeadSnooze', id?: any | null, active: boolean, note?: string | null, leadActionAfterAllSnoozeSteps?: Types.LeadActionAfterAllSnoozeSteps | null, isAiGenerated: boolean, snoozeSteps?: Array<{ __typename: 'SnoozeStep', active: boolean, messageSourceId?: any | null, isMessageSourceSuggestedMessage: boolean, wakeupTime: any, message?: string | null, title?: string | null, messageSentAt?: any | null, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null }> | null } | null };

export type SnoozeStepForFollowUpFragment = { __typename: 'SnoozeStep', active: boolean, messageSourceId?: any | null, isMessageSourceSuggestedMessage: boolean, wakeupTime: any, message?: string | null, title?: string | null, messageSentAt?: any | null, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null };

export type GenerateLeadSuggestedMessagesForFollowUpMutationVariables = Types.Exact<{
  input: Types.GenerateSingleLeadSuggestedMessageInput;
}>;


export type GenerateLeadSuggestedMessagesForFollowUpMutation = { __typename: 'Mutation', generateSingleLeadSuggestedMessage: { __typename: 'LeadSuggestedMessage', id: string, message: string, suggestedMessageRevision: { __typename: 'SuggestedMessageRevision', id: any, title: string } } };

export type SuggestSnoozeStepMessagesForFollowUpQueryVariables = Types.Exact<{
  input: Types.SuggestSnoozeStepMessagesInput;
}>;


export type SuggestSnoozeStepMessagesForFollowUpQuery = { __typename: 'Query', suggestSnoozeStepMessages: { __typename: 'SuggestSnoozeStepMessagesPayload', messages: Array<string>, trackingId?: any | null } };

export type SuggestedMessageRevisionsPaginationForFollowUpQueryVariables = Types.Exact<{
  filter: Types.SuggestedMessageRevisionFilterInput;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type SuggestedMessageRevisionsPaginationForFollowUpQuery = { __typename: 'Query', suggestedMessageRevisionsIncludeSystemTypePagination: { __typename: 'SuggestedMessageRevisionsPagination', revisions: Array<{ __typename: 'SuggestedMessageRevision', id: any, title: string, message: string, starred?: boolean | null, type: Types.SuggestedMessageRevisionType, tags: Array<string>, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null }> } };

export const SnoozeStepForFollowUpFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SnoozeStepForFollowUp"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SnoozeStep"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"active"}},{"kind":"Field","name":{"kind":"Name","value":"messageSourceId"}},{"kind":"Field","name":{"kind":"Name","value":"isMessageSourceSuggestedMessage"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SnoozeStepForSnoozeStep"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SnoozeStepForIsInvalidSnoozeStep"}}]}}]} as unknown as DocumentNode;
export const LeadForFollowUpFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForFollowUp"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"snooze"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"active"}},{"kind":"Field","name":{"kind":"Name","value":"note"}},{"kind":"Field","name":{"kind":"Name","value":"leadActionAfterAllSnoozeSteps"}},{"kind":"Field","name":{"kind":"Name","value":"snoozeSteps"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SnoozeStepForFollowUp"}}]}},{"kind":"Field","name":{"kind":"Name","value":"isAiGenerated"}}]}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForConversationSummaryButton"}}]}}]} as unknown as DocumentNode;
export const GenerateLeadSuggestedMessagesForFollowUpDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateLeadSuggestedMessagesForFollowUp"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateSingleLeadSuggestedMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateSingleLeadSuggestedMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"suggestedMessageRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]}}]} as unknown as DocumentNode;
export type GenerateLeadSuggestedMessagesForFollowUpMutationFn = Apollo.MutationFunction<GenerateLeadSuggestedMessagesForFollowUpMutation, GenerateLeadSuggestedMessagesForFollowUpMutationVariables>;
export type GenerateLeadSuggestedMessagesForFollowUpComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateLeadSuggestedMessagesForFollowUpMutation, GenerateLeadSuggestedMessagesForFollowUpMutationVariables>, 'mutation'>;

    export const GenerateLeadSuggestedMessagesForFollowUpComponent = (props: GenerateLeadSuggestedMessagesForFollowUpComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateLeadSuggestedMessagesForFollowUpMutation, GenerateLeadSuggestedMessagesForFollowUpMutationVariables> mutation={GenerateLeadSuggestedMessagesForFollowUpDocument} {...props} />
    );
    

/**
 * __useGenerateLeadSuggestedMessagesForFollowUpMutation__
 *
 * To run a mutation, you first call `useGenerateLeadSuggestedMessagesForFollowUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLeadSuggestedMessagesForFollowUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLeadSuggestedMessagesForFollowUpMutation, { data, loading, error }] = useGenerateLeadSuggestedMessagesForFollowUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateLeadSuggestedMessagesForFollowUpMutation(baseOptions?: Apollo.MutationHookOptions<GenerateLeadSuggestedMessagesForFollowUpMutation, GenerateLeadSuggestedMessagesForFollowUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateLeadSuggestedMessagesForFollowUpMutation, GenerateLeadSuggestedMessagesForFollowUpMutationVariables>(GenerateLeadSuggestedMessagesForFollowUpDocument, options);
      }
export type GenerateLeadSuggestedMessagesForFollowUpMutationHookResult = ReturnType<typeof useGenerateLeadSuggestedMessagesForFollowUpMutation>;
export type GenerateLeadSuggestedMessagesForFollowUpMutationResult = Apollo.MutationResult<GenerateLeadSuggestedMessagesForFollowUpMutation>;
export type GenerateLeadSuggestedMessagesForFollowUpMutationOptions = Apollo.BaseMutationOptions<GenerateLeadSuggestedMessagesForFollowUpMutation, GenerateLeadSuggestedMessagesForFollowUpMutationVariables>;
export const SuggestSnoozeStepMessagesForFollowUpDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SuggestSnoozeStepMessagesForFollowUp"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SuggestSnoozeStepMessagesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"suggestSnoozeStepMessages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"messages"}},{"kind":"Field","name":{"kind":"Name","value":"trackingId"}}]}}]}}]} as unknown as DocumentNode;
export type SuggestSnoozeStepMessagesForFollowUpComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SuggestSnoozeStepMessagesForFollowUpQuery, SuggestSnoozeStepMessagesForFollowUpQueryVariables>, 'query'> & ({ variables: SuggestSnoozeStepMessagesForFollowUpQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SuggestSnoozeStepMessagesForFollowUpComponent = (props: SuggestSnoozeStepMessagesForFollowUpComponentProps) => (
      <ApolloReactComponents.Query<SuggestSnoozeStepMessagesForFollowUpQuery, SuggestSnoozeStepMessagesForFollowUpQueryVariables> query={SuggestSnoozeStepMessagesForFollowUpDocument} {...props} />
    );
    

/**
 * __useSuggestSnoozeStepMessagesForFollowUpQuery__
 *
 * To run a query within a React component, call `useSuggestSnoozeStepMessagesForFollowUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestSnoozeStepMessagesForFollowUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestSnoozeStepMessagesForFollowUpQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuggestSnoozeStepMessagesForFollowUpQuery(baseOptions: Apollo.QueryHookOptions<SuggestSnoozeStepMessagesForFollowUpQuery, SuggestSnoozeStepMessagesForFollowUpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestSnoozeStepMessagesForFollowUpQuery, SuggestSnoozeStepMessagesForFollowUpQueryVariables>(SuggestSnoozeStepMessagesForFollowUpDocument, options);
      }
export function useSuggestSnoozeStepMessagesForFollowUpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestSnoozeStepMessagesForFollowUpQuery, SuggestSnoozeStepMessagesForFollowUpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestSnoozeStepMessagesForFollowUpQuery, SuggestSnoozeStepMessagesForFollowUpQueryVariables>(SuggestSnoozeStepMessagesForFollowUpDocument, options);
        }
export function useSuggestSnoozeStepMessagesForFollowUpSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SuggestSnoozeStepMessagesForFollowUpQuery, SuggestSnoozeStepMessagesForFollowUpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SuggestSnoozeStepMessagesForFollowUpQuery, SuggestSnoozeStepMessagesForFollowUpQueryVariables>(SuggestSnoozeStepMessagesForFollowUpDocument, options);
        }
export type SuggestSnoozeStepMessagesForFollowUpQueryHookResult = ReturnType<typeof useSuggestSnoozeStepMessagesForFollowUpQuery>;
export type SuggestSnoozeStepMessagesForFollowUpLazyQueryHookResult = ReturnType<typeof useSuggestSnoozeStepMessagesForFollowUpLazyQuery>;
export type SuggestSnoozeStepMessagesForFollowUpSuspenseQueryHookResult = ReturnType<typeof useSuggestSnoozeStepMessagesForFollowUpSuspenseQuery>;
export type SuggestSnoozeStepMessagesForFollowUpQueryResult = Apollo.QueryResult<SuggestSnoozeStepMessagesForFollowUpQuery, SuggestSnoozeStepMessagesForFollowUpQueryVariables>;
export const SuggestedMessageRevisionsPaginationForFollowUpDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SuggestedMessageRevisionsPaginationForFollowUp"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SuggestedMessageRevisionFilterInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"offset"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"suggestedMessageRevisionsIncludeSystemTypePagination"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}},{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}},{"kind":"Argument","name":{"kind":"Name","value":"offset"},"value":{"kind":"Variable","name":{"kind":"Name","value":"offset"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"SuggestedMessageRevisionForSuggestedMessageDisplay"}}]}}]}}]}},...SuggestedMessageRevisionForSuggestedMessageDisplayFragmentDoc.definitions,...MediaFileFragmentDoc.definitions]} as unknown as DocumentNode;
export type SuggestedMessageRevisionsPaginationForFollowUpComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SuggestedMessageRevisionsPaginationForFollowUpQuery, SuggestedMessageRevisionsPaginationForFollowUpQueryVariables>, 'query'> & ({ variables: SuggestedMessageRevisionsPaginationForFollowUpQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SuggestedMessageRevisionsPaginationForFollowUpComponent = (props: SuggestedMessageRevisionsPaginationForFollowUpComponentProps) => (
      <ApolloReactComponents.Query<SuggestedMessageRevisionsPaginationForFollowUpQuery, SuggestedMessageRevisionsPaginationForFollowUpQueryVariables> query={SuggestedMessageRevisionsPaginationForFollowUpDocument} {...props} />
    );
    

/**
 * __useSuggestedMessageRevisionsPaginationForFollowUpQuery__
 *
 * To run a query within a React component, call `useSuggestedMessageRevisionsPaginationForFollowUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedMessageRevisionsPaginationForFollowUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedMessageRevisionsPaginationForFollowUpQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSuggestedMessageRevisionsPaginationForFollowUpQuery(baseOptions: Apollo.QueryHookOptions<SuggestedMessageRevisionsPaginationForFollowUpQuery, SuggestedMessageRevisionsPaginationForFollowUpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestedMessageRevisionsPaginationForFollowUpQuery, SuggestedMessageRevisionsPaginationForFollowUpQueryVariables>(SuggestedMessageRevisionsPaginationForFollowUpDocument, options);
      }
export function useSuggestedMessageRevisionsPaginationForFollowUpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedMessageRevisionsPaginationForFollowUpQuery, SuggestedMessageRevisionsPaginationForFollowUpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestedMessageRevisionsPaginationForFollowUpQuery, SuggestedMessageRevisionsPaginationForFollowUpQueryVariables>(SuggestedMessageRevisionsPaginationForFollowUpDocument, options);
        }
export function useSuggestedMessageRevisionsPaginationForFollowUpSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SuggestedMessageRevisionsPaginationForFollowUpQuery, SuggestedMessageRevisionsPaginationForFollowUpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SuggestedMessageRevisionsPaginationForFollowUpQuery, SuggestedMessageRevisionsPaginationForFollowUpQueryVariables>(SuggestedMessageRevisionsPaginationForFollowUpDocument, options);
        }
export type SuggestedMessageRevisionsPaginationForFollowUpQueryHookResult = ReturnType<typeof useSuggestedMessageRevisionsPaginationForFollowUpQuery>;
export type SuggestedMessageRevisionsPaginationForFollowUpLazyQueryHookResult = ReturnType<typeof useSuggestedMessageRevisionsPaginationForFollowUpLazyQuery>;
export type SuggestedMessageRevisionsPaginationForFollowUpSuspenseQueryHookResult = ReturnType<typeof useSuggestedMessageRevisionsPaginationForFollowUpSuspenseQuery>;
export type SuggestedMessageRevisionsPaginationForFollowUpQueryResult = Apollo.QueryResult<SuggestedMessageRevisionsPaginationForFollowUpQuery, SuggestedMessageRevisionsPaginationForFollowUpQueryVariables>;