import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import { Grammarly } from '@grammarly/editor-sdk-react'
import { PageKeyPriority, Redirect, Route, Switch } from '@deal/router'
import { CuratedTermsOfUseType, ExpertApplicationState, Permission } from '#src/generated/types'
import { isApplicant, isImpersonating } from '#src/app/utilities/identity'
import { isSupportedBrowser } from '#src/app/services/supportedBrowsers'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { UserAgentContext } from '#src/app/context/UserAgent'
import { useLuxTrackingContext } from '#src/app/context/LuxTracking'
import { BusinessUserProvider } from '#src/app/context/BusinessUser'
import NotificationOpenEvent from '#src/app/containers/NotificationOpenEvent'
import NavigateOnEvent from '#src/app/containers/NavigateOnEvent'
import { HostAwareConfigContext } from '#src/app/containers/HostAwareConfig'
import ChatProvider from '#src/app/containers/Chat'
import Authenticate from '#src/app/containers/Authenticate'
import UnsupportedBrowserTakeover from '#src/app/components/UnsupportedBrowserTakeover'
import ToastContainer from '#src/app/components/ToastContainer'
import TermsOfUseAgreementModal from '#src/app/components/TermsOfUseAgreementModal/index'
import QuickRepliesV2IntroPopup from '#src/app/components/QuickRepliesV2IntroPopover'
import NewMessagePageTitleNotification from '#src/app/components/NewMessagePageTitleNotification'
import NewMessageNotification from '#src/app/components/NewMessageNotification'
import Navigation from '#src/app/components/Navigation'
import ManageQuickRepliesModal from '#src/app/components/ManageQuickRepliesModal'
import LeadSLABanner from '#src/app/components/LeadSLABanner'
import LeadIntentChangedSubscription from '#src/app/components/LeadIntentChangedSubscription'
import LeadActivitySubscription from '#src/app/components/LeadActivitySubscription'
import HelpCenterWidget from '#src/app/components/HelpCenterWidget'
import ExpertPhoneCalls from '#src/app/components/ExpertPhoneCalls'
import ExpertNotificationSubscription from '#src/app/components/ExpertNotificationSubscription'
import Confetti from '#src/app/components/Confetti'
import AccountsNeedAttentionSubscription from '#src/app/components/AccountsNeedAttentionSubscription'
import { useHasPermission } from '../utilities/permission'
import { useShowAppcuesOnlineNudge } from '../hooks/useShowAppcuesOnlineNudge'
import { HelpCenterNewReleasesProvider } from '../context/HelpCenterNewReleases'
import { useIdentityContext } from '../containers/Identity'
import { UnreadTicketActivityNotification } from '../components/UnreadTicketActivitySubscription'
import ExtendedAwayBanner from '../components/ExtendedAwayBanner'
import ExpertPathModal from '../components/ExpertPathModal'
import * as Routes from './routes'
import NewAiReEngagementMessageSubscription from '#src/app/components/NewAiReEngagementMessageSubscription'
import { LeadTabsContextProvider } from '../context/LeadTabs'

const Router: React.FC = () => {
  const userAgent = useContext(UserAgentContext)
  const config = useContext(HostAwareConfigContext)
  const clientId = config.get('grammarly.client_id')

  return (
    <Switch>
      <Route
        path="/auth"
        pageKey="auth"
        priority={PageKeyPriority.TOP_LEVEL}
        component={Routes.Auth}
      />
      {userAgent && (isSupportedBrowser(userAgent) || userAgent.isBot) ? null : (
        <UnsupportedBrowserTakeover />
      )}
      <Authenticate>
        {businessUser => {
          return (
            <BusinessUserProvider businessUser={businessUser}>
              <HelpCenterNewReleasesProvider>
                {/* @ts-ignore: Grammarly does not have up to date types */}
                <Grammarly
                  clientId={clientId}
                  config={{
                    introText: 'Curated added Grammarly to help you write clearly and mistake-free.'
                  }}
                >
                  <InternalRoutes />
                </Grammarly>
              </HelpCenterNewReleasesProvider>
            </BusinessUserProvider>
          )
        }}
      </Authenticate>
    </Switch>
  )
}

const InternalRoutes: React.FC = () => {
  const businessUser = useBusinessUser()
  const isExpertApplicant = isApplicant(businessUser)
  const { myself } = useIdentityContext()

  const [canUseChat] = useHasPermission(Permission.INAPP_CHAT)

  const applicationState = businessUser.expertApplication?.state

  const inDeferralProcess =
    applicationState === ExpertApplicationState.DEFERRED ||
    applicationState === ExpertApplicationState.WITHDRAWN ||
    applicationState === ExpertApplicationState.PENDING_DEFERRAL

  // Only show the terms of use modal if the expert has been fully onboarded and has not already signed this year
  // Force them to sign the terms of use before they can access the app
  const shouldShowTermsOfUseModal =
    !isImpersonating(myself) &&
    !businessUser.hasAgreedToCuratedTermsAndConditions &&
    businessUser.active &&
    businessUser.expertApplication?.state === ExpertApplicationState.ONBOARDED &&
    // force hide the modal for now - the terms are currently being updated
    // and experts will get a two week notice before they need to sign again
    // This can be removeed at that time
    false

  const app = (
    <LeadTabsContextProvider>
      {/* Toast container needs to be inside BusinessUserProvider for toasts to access context */}
      <ToastContainer />
      {/* There are paths used for expert knowledge tests (ekt) during onboarding */}
      <ExpertPathModal />
      <TermsOfUseAgreementModal
        isNewExpert={false}
        type={CuratedTermsOfUseType.TERMS_AND_CONDITIONS}
        isOpen={shouldShowTermsOfUseModal}
      />
      {isExpertApplicant || inDeferralProcess ? (
        <ExpertApplicantRoutes
          hideNavigation={inDeferralProcess || !businessUser.expertApplication?.hiringFlow}
        />
      ) : (
        <ExpertRoutes />
      )}
    </LeadTabsContextProvider>
  )

  if (canUseChat) {
    return <ChatProvider>{app}</ChatProvider>
  } else {
    return app
  }
}

const ExpertApplicantRoutes: React.FC<React.PropsWithChildren<{ hideNavigation: boolean }>> = ({
  hideNavigation
}) => {
  return (
    <>
      <Switch>
        <Route
          path="/profile"
          pageKey="profile"
          priority={PageKeyPriority.TOP_LEVEL}
          component={Routes.Profile}
        />
        <Route
          render={() => {
            /**
             * Wrap all needed routes in Navigation component
             * Wrapping at the route level causes a flash & remount when route changes
             *
             * If applicant is in deferral process we don't want to show the navigation
             */
            return hideNavigation ? (
              <Switch>
                <Route
                  path="/onboarding"
                  pageKey="onboarding"
                  priority={PageKeyPriority.TOP_LEVEL}
                  component={Routes.Onboarding}
                />
                <Route
                  path="/training"
                  pageKey="training"
                  priority={PageKeyPriority.TOP_LEVEL}
                  component={Routes.Training}
                />
                <Route render={() => <Redirect to="/onboarding" />} />
              </Switch>
            ) : (
              <Navigation>
                <Switch>
                  <Route
                    path="/onboarding"
                    pageKey="onboarding"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Onboarding}
                  />
                  <Route
                    path="/training"
                    pageKey="training"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Training}
                  />
                  <Route render={() => <Redirect to="/onboarding" />} />
                </Switch>
              </Navigation>
            )
          }}
        />
      </Switch>
    </>
  )
}

const ExpertRoutes: React.FC = () => {
  const { luxInit } = useLuxTrackingContext()
  const businessUser = useBusinessUser()
  useShowAppcuesOnlineNudge()

  const [canViewLeads, canViewGigs] = useHasPermission(Permission.LEAD_READ, Permission.GIG_READ)

  return (
    <>
      {businessUser.active && businessUser.isExpert && <LeadIntentChangedSubscription />}
      <NewMessageNotification />
      <NewMessagePageTitleNotification />
      <LeadActivitySubscription />
      <ExpertNotificationSubscription />
      <AccountsNeedAttentionSubscription />
      <NotificationOpenEvent />
      <NavigateOnEvent />
      <ExpertPhoneCalls />
      <Confetti />
      <ManageQuickRepliesModal />
      <LeadSLABanner />
      <ExtendedAwayBanner />
      <UnreadTicketActivityNotification />
      <NewAiReEngagementMessageSubscription />
      {/* Skip during SSR because of getGuru request timeout issues*/}
      {process.env.TARGET === 'web' && ReactDOM.createPortal(<HelpCenterWidget />, document.body)}
      {process.env.TARGET === 'web' && <QuickRepliesV2IntroPopup />}
      <Switch>
        <Route
          path="/profile"
          pageKey="profile"
          priority={PageKeyPriority.TOP_LEVEL}
          component={Routes.Profile}
        />
        <Route
          path="/curate"
          pageKey="curate"
          priority={PageKeyPriority.TOP_LEVEL}
          render={({ location }) => {
            const pathnameArray = location.pathname.split('/')
            const mode = pathnameArray[pathnameArray.length - 1]
            if (mode === 'edit') {
              luxInit('curate-products-edit')
            } else if (mode === 'search') {
              luxInit('curate-products-search')
            }
            return <Routes.Curate />
          }}
        />
        <Route
          render={() => {
            /**
             * Wrap all needed routes in Navigation component
             * Wrapping at the route level causes a flash & remount when route changes
             */
            return (
              <Navigation>
                <Switch>
                  <Route
                    path="/inbox/:leadId?"
                    pageKey="inbox"
                    priority={PageKeyPriority.TOP_LEVEL}
                    render={({
                      match: {
                        params: { leadId }
                      }
                    }) => {
                      if (leadId) {
                        luxInit('inbox-conversation')
                      }
                      if (!canViewLeads && canViewGigs) {
                        return <Redirect to="/content/my-products" />
                      } else if (!canViewLeads) {
                        return <Redirect to="/onboarding" />
                      }
                      return <Routes.Inbox />
                    }}
                  />
                  <Route
                    path="/notifications"
                    pageKey="notifications"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Notifications}
                  />
                  <Route
                    path="/reports"
                    pageKey="reports"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Reports}
                  />
                  <Route
                    path="/performance"
                    pageKey="performance"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Performance}
                  />
                  <Route
                    path="/acquisition"
                    pageKey="acquisition"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Acquisition}
                  />
                  <Route
                    path="/influencer-program"
                    pageKey="influencer-program"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.InfluencerProgram}
                  />
                  <Route
                    path="/emails"
                    pageKey="emails"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Emails}
                  />
                  <Route
                    path="/community"
                    pageKey="community"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Community}
                  />
                  <Route
                    path="/content"
                    pageKey="content"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Content}
                  />
                  <Route
                    path="/onboarding"
                    pageKey="onboarding"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Onboarding}
                  />
                  <Route
                    path="/scheduling"
                    pageKey="scheduling"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Scheduling}
                  />
                  <Route
                    path="/team"
                    pageKey="team"
                    priority={PageKeyPriority.TOP_LEVEL}
                    render={() => <Redirect to="/inbox" />}
                  />
                  <Route
                    path="/training"
                    pageKey="training"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Training}
                  />
                  <Route
                    path="/help-center"
                    pageKey="help-center"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.HelpCenter}
                  />
                  <Route
                    path="/tickets"
                    pageKey="tickets"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.Tickets}
                  />
                  <Route
                    path="/pdp-review"
                    pageKey="pdp-review"
                    priority={PageKeyPriority.TOP_LEVEL}
                    component={Routes.PDPReview}
                  />
                  <Route path="/hiring" render={() => <Redirect to="/acquisition" />} />
                  <Route
                    render={({ location }) => (
                      <Redirect
                        to={{
                          pathname: canViewLeads
                            ? '/inbox'
                            : canViewGigs
                            ? '/content/my-products'
                            : '/onboarding',
                          search: location.search
                        }}
                      />
                    )}
                  />
                </Switch>
              </Navigation>
            )
          }}
        />
      </Switch>
    </>
  )
}

export default Router
