import { useExperiment } from '@deal/experiment-js'

const useScoutLeadPopupExperiment = () => {
  const { result } = useExperiment({
    experiment: 'biz-app-scout-lead-popup',
    defaultTreatment: 'control'
  })

  return result === 'on'
}

export default useScoutLeadPopupExperiment
