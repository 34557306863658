// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { LeadForConversationSummaryButtonFragmentDoc } from '../../components/ConversationSummaryButton/ConversationSummaryButton.generated';
import { LeadForCurationSummaryHelperFragmentDoc } from './CurationSummaryHelper/CurationSummaryHelper.generated';
import { LeadOwnershipFragmentDoc } from '../../fragments/leadOwnership.generated';
import { LeadForScoutLeadOnboardingFragmentDoc } from './ScoutLeadOnboarding/ScoutLeadOnboarding.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type LeadForExpertChatFragment = { __typename: 'Lead', id: any, conversationSummaryEligible: boolean, expertAcknowledgedScoutTransfer: boolean, consumer: { __typename: 'User', id: any, experimentUserId: any, firstName?: string | null }, conversation: { __typename: 'Conversation', id: any, externalId: string, isAiGuestChat: boolean, isAiChatV2: boolean }, currentExpertConversationMetrics: { __typename: 'ConversationMetrics', manualMessageCount?: number | null, lastMessageSummary?: { __typename: 'MessageSummary', text?: string | null, type: Types.MessageBodyType } | null }, delegateExpert?: { __typename: 'BusinessUser', id: any } | null, expert: { __typename: 'BusinessUser', id: any } };

export type BusinessUserForExpertChatFragment = { __typename: 'BusinessUser', id: any, department: { __typename: 'Department', id: any, topLevelCategory: { __typename: 'Category', id: any, slug: string } }, expertDeviceLLMPreferences: Array<{ __typename: 'ExpertDeviceLLMPreference', type: Types.ExpertDeviceType, isOptedIntoLLMMessageSuggestions: boolean }> };

export type ExpertRecordedCurationVideoForExpertChatSubscriptionVariables = Types.Exact<{
  expertId: Types.Scalars['BusinessUserId']['input'];
  leadId: Types.Scalars['LeadId']['input'];
}>;


export type ExpertRecordedCurationVideoForExpertChatSubscription = { __typename: 'Subscription', expertRecordedCurationVideo: { __typename: 'ExpertVideoRecording', id: any, shareUrl: string } };

export const LeadForExpertChatFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LeadForExpertChat"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Lead"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForConversationSummaryButton"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForCurationSummaryHelper"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LeadForScoutLeadOnboarding"}}]}}]} as unknown as DocumentNode;
export const BusinessUserForExpertChatFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BusinessUserForExpertChat"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUser"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"department"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"topLevelCategory"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"expertDeviceLLMPreferences"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"isOptedIntoLLMMessageSuggestions"}}]}}]}}]} as unknown as DocumentNode;
export const ExpertRecordedCurationVideoForExpertChatDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"ExpertRecordedCurationVideoForExpertChat"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BusinessUserId"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LeadId"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"expertRecordedCurationVideo"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"expertId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"expertId"}}},{"kind":"Argument","name":{"kind":"Name","value":"leadId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"leadId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"shareUrl"}}]}}]}}]} as unknown as DocumentNode;
export type ExpertRecordedCurationVideoForExpertChatComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<ExpertRecordedCurationVideoForExpertChatSubscription, ExpertRecordedCurationVideoForExpertChatSubscriptionVariables>, 'subscription'>;

    export const ExpertRecordedCurationVideoForExpertChatComponent = (props: ExpertRecordedCurationVideoForExpertChatComponentProps) => (
      <ApolloReactComponents.Subscription<ExpertRecordedCurationVideoForExpertChatSubscription, ExpertRecordedCurationVideoForExpertChatSubscriptionVariables> subscription={ExpertRecordedCurationVideoForExpertChatDocument} {...props} />
    );
    

/**
 * __useExpertRecordedCurationVideoForExpertChatSubscription__
 *
 * To run a query within a React component, call `useExpertRecordedCurationVideoForExpertChatSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExpertRecordedCurationVideoForExpertChatSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertRecordedCurationVideoForExpertChatSubscription({
 *   variables: {
 *      expertId: // value for 'expertId'
 *      leadId: // value for 'leadId'
 *   },
 * });
 */
export function useExpertRecordedCurationVideoForExpertChatSubscription(baseOptions: Apollo.SubscriptionHookOptions<ExpertRecordedCurationVideoForExpertChatSubscription, ExpertRecordedCurationVideoForExpertChatSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ExpertRecordedCurationVideoForExpertChatSubscription, ExpertRecordedCurationVideoForExpertChatSubscriptionVariables>(ExpertRecordedCurationVideoForExpertChatDocument, options);
      }
export type ExpertRecordedCurationVideoForExpertChatSubscriptionHookResult = ReturnType<typeof useExpertRecordedCurationVideoForExpertChatSubscription>;
export type ExpertRecordedCurationVideoForExpertChatSubscriptionResult = Apollo.SubscriptionResult<ExpertRecordedCurationVideoForExpertChatSubscription>;