import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from '@deal/router'
import { LeadForLeadTabsFragment } from './LeadTabs.generated'

type LeadForLeadTabValue = LeadForLeadTabsFragment | null

export type LeadTabValue = {
  lead?: LeadForLeadTabValue
  location: { pathname: string; search: string }
}

type LeadTabsContextType = {
  leadTabs: LeadTabValue[]
  currentLeadTab?: LeadTabValue
  currentLeadTabIndex: number
  openNewLeadTab: (lead?: LeadForLeadTabValue) => void
  closeLeadTab: (index: number) => void
  setCurrentLeadTabIndex: (index: number) => void
  setCurrentLeadTabLead: (lead?: LeadForLeadTabsFragment) => void
  replaceHistoryWithCurrentLeadTabUrl: () => void
}

const LeadTabsContext = React.createContext<LeadTabsContextType>({
  leadTabs: [],
  currentLeadTab: {
    location: { pathname: '/inbox', search: '' }
  },
  currentLeadTabIndex: 0,
  openNewLeadTab: () => {},
  closeLeadTab: () => {},
  setCurrentLeadTabIndex: () => {},
  setCurrentLeadTabLead: () => {},
  replaceHistoryWithCurrentLeadTabUrl: () => {}
})

const buildLocation = (lead?: LeadForLeadTabValue) => {
  return { pathname: lead ? `/inbox/${lead.id}` : '/inbox', search: '' }
}

const LeadTabsContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { push, location } = useHistory()
  const [currentLeadTabIndex, setCurrentLeadTabIndex] = useState(0)
  const [leadTabs, setLeadTabs] = useState<LeadTabValue[]>([
    {
      location: buildLocation()
    }
  ])

  const replaceHistoryWithCurrentLeadTabUrl = () => {
    const currentLeadTab = leadTabs.at(currentLeadTabIndex)
    if (currentLeadTab) {
      replaceHistoryWithLeadTabUrl(currentLeadTab)
    }
  }

  const replaceHistoryWithLeadTabUrl = (leadTab: LeadTabValue) => {
    push(leadTab.location)
  }

  useEffect(() => {
    if (currentLeadTabIndex >= leadTabs.length) {
      setCurrentLeadTabIndex(leadTabs.length - 1)
    }
  }, [leadTabs.length])

  return (
    <LeadTabsContext.Provider
      value={{
        leadTabs,
        currentLeadTabIndex,
        /*
         * Open a new lead tab
         * 1. Add the new tab to the list of tabs
         * 2. Update the location of the previous tab
         * 3. Set the new tab as the current tab
         */
        openNewLeadTab: lead => {
          const newTabs = [...leadTabs, { lead, location: buildLocation(lead) }]
          newTabs[currentLeadTabIndex] = { ...newTabs[currentLeadTabIndex], location }
          setLeadTabs(newTabs)
          setCurrentLeadTabIndex(newTabs.length - 1)
          replaceHistoryWithLeadTabUrl(newTabs.at(-1)!)
        },
        /*
         * Close a lead tab
         * 1. Remove the tab from the list of tabs
         * 2. If the tab being closed is the current tab, replace the history with the tab that will replace it (if there is one).
         * 3. If the tab being closed is the last tab, set the current tab to the previous tab.
         */
        closeLeadTab: index => {
          setLeadTabs(currentTabs => {
            const newTabs = [...currentTabs]
            newTabs.splice(index, 1)

            if (index === currentLeadTabIndex && newTabs.at(index)) {
              replaceHistoryWithLeadTabUrl(newTabs.at(index)!)
            }

            if (currentLeadTabIndex >= newTabs.length) {
              setCurrentLeadTabIndex(newTabs.length - 1)
            }

            return newTabs
          })
        },
        /*
         * Set the current lead tab index
         * 1. Update the location of the previous tab
         * 2. Update the history to that of the new tab
         * 3. Set the new index
         */
        setCurrentLeadTabIndex: index => {
          setCurrentLeadTabIndex(prevIndex => {
            if (index === prevIndex) {
              return prevIndex
            }

            setLeadTabs(currentLeadTabs => {
              const newLeadTabs = [...currentLeadTabs]
              newLeadTabs[prevIndex] = { ...newLeadTabs[prevIndex], location }
              const newActiveLead = newLeadTabs.at(index)
              newActiveLead && replaceHistoryWithLeadTabUrl(newActiveLead)
              return newLeadTabs
            })
            return index
          })
        },
        /**
         * Update the lead of the current tab
         */
        setCurrentLeadTabLead: lead => {
          setLeadTabs(currentTabs => {
            const newTabs = [...currentTabs]
            const newTabValue = {
              lead,
              location: buildLocation(lead)
            }
            newTabs[currentLeadTabIndex] = newTabValue
            return newTabs
          })
        },
        replaceHistoryWithCurrentLeadTabUrl
      }}
    >
      {children}
    </LeadTabsContext.Provider>
  )
}

const useLeadTabsContext = () => {
  const leadTabsContext = useContext(LeadTabsContext)

  if (!leadTabsContext) {
    throw new Error('useLeadtabsContext must be used within a LeadTabsContextProvider')
  }

  return leadTabsContext
}

export { LeadTabsContextProvider, useLeadTabsContext }
