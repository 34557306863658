import React from 'react'
import {
  LeadActivityType,
  LeadSourceFlowType,
  Permission,
  UserSourceType
} from '#src/generated/types'
import { useHasPermission } from '#src/app/utilities/permission'
import newLeadToast from '#src/app/toasts/newLeadToast'
import leadPaymentSucceededToast from '#src/app/toasts/leadPaymentSucceededToast'
import leadPaymentFailedToast from '#src/app/toasts/leadPaymentFailedToast'
import consumerReviewAddedToast from '#src/app/toasts/consumerReviewAddedToast'
import connectPartnerOrderToast from '#src/app/toasts/connectPartnerOrderToast'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { useLeadActivityOccurredSubscription } from './LeadActivityOccurred.generated'
import { useExperimentClientContext } from '@deal/experiment-js'
import newScoutLeadToast from '#src/app/toasts/newScoutLeadToast'
import useScoutLeadPopupExperiment from '#src/app/hooks/useScoutLeadPopupExperiment'

const LeadActivitySubscription: React.FC = () => {
  const businessUser = useBusinessUser()
  const experimentClient = useExperimentClientContext()
  const isScoutLeadPopupExperimentEnabled = useScoutLeadPopupExperiment()

  const [canViewLeadActivity] = useHasPermission(Permission.LEAD_ACTIVITY_READ)

  useLeadActivityOccurredSubscription({
    skip: !canViewLeadActivity,
    variables: {
      types: [
        LeadActivityType.LEAD_CREATED,
        LeadActivityType.CONSUMER_FIRST_MESSAGE_SENT,
        LeadActivityType.CONSUMER_CALLED,
        LeadActivityType.CONSUMER_ORDER_PAID,
        LeadActivityType.CONSUMER_PAYMENT_SUCCEEDED,
        LeadActivityType.CONSUMER_PAYMENT_FAILED,
        LeadActivityType.CONSUMER_REVIEW_ADDED,
        LeadActivityType.CONNECT_PARTNER_ORDER_CREATED,
        LeadActivityType.REASSIGNED
      ],
      expertId: businessUser.id
    },
    onData: ({ data: { data } }) => {
      if (data) {
        switch (data.leadActivityOccurred.__typename) {
          case 'LeadPaymentSucceededActivity':
            leadPaymentSucceededToast(data.leadActivityOccurred)
            break
          case 'LeadConnectPartnerOrderActivity':
            connectPartnerOrderToast(data.leadActivityOccurred)
            break
          case 'LeadPaymentFailedActivity':
            leadPaymentFailedToast(data.leadActivityOccurred)
            break
          case 'ConsumerFirstMessageSentActivity':
            const hideNewLeadToast =
              experimentClient.treatment({
                experiment: 'biz-app-hide-new-lead-notifications',
                defaultTreatment: 'control',
                overrideId: data.leadActivityOccurred.lead.consumer.experimentUserId
              }).result === 'on'

            !hideNewLeadToast && newLeadToast(data.leadActivityOccurred)
            break
          case 'ConsumerReviewAddedActivity':
            // Only show experts good reviews
            consumerReviewAddedToast(data.leadActivityOccurred)
            break
          case 'LeadCreatedActivity':
            // notify the expert immediately for byol and conversation starter leads
            if (
              data.leadActivityOccurred.lead.sourceFlow?.type ===
                LeadSourceFlowType.CONVERSATION_STARTER ||
              data.leadActivityOccurred.lead.consumer.source?.type ===
                UserSourceType.EXPERT_REFERRAL
            ) {
              newLeadToast(data.leadActivityOccurred)
            }
            break
          case 'LeadReassignedActivity':
            const {
              leadActivityOccurred: {
                lead: {
                  conversation: { isAiChatV2, isAiGuestChat }
                },
                previousExpert: {
                  expertAttributes: { placeholderExpert }
                }
              }
            } = data
            if (
              isScoutLeadPopupExperimentEnabled &&
              placeholderExpert &&
              (isAiChatV2 || isAiGuestChat)
            ) {
              newScoutLeadToast(data.leadActivityOccurred)
            }
        }
      }
    }
  })

  return null
}

export default LeadActivitySubscription
