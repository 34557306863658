import React, { useEffect } from 'react'
import { Button, Text } from '@deal/bluxome'
import { useHistory } from '@deal/router'
import { useLeadTabsContext } from '#src/app/context/LeadTabs'
import useLeadTabsExperiment from '#src/app/hooks/useLeadTabsExperiment'
import { playNotificationSound } from '#src/app/services/desktopNotification'
import Toast from '#src/app/components/Toast'
import formattedToast from '../formattedToast'
import { LeadReassignedActivityForLeadActivityOccurredFragment } from '../../components/LeadActivitySubscription/LeadActivityOccurred.generated'
import styles from './styles.css'

interface Props {
  activity: LeadReassignedActivityForLeadActivityOccurredFragment
  closeToast?: () => void
}

const NewScoutLeadToast: React.FC<Props> = ({ closeToast, activity }) => {
  const shouldShowTabs = useLeadTabsExperiment()
  const { openNewLeadTab } = useLeadTabsContext()
  const history = useHistory()
  const { id: leadId, consumer } = activity.lead

  useEffect(() => {
    const playSound = window.localStorage.getItem('playSoundForNewLead') === 'on'
    if (playSound) {
      playNotificationSound()
    }
  }, [leadId])

  return (
    <Toast className={styles.toastContainer}>
      <div className={styles.text}>
        <Text style="extra-small" color="neutrals-600">
          New lead from Scout
        </Text>
        <Text color="neutrals-black">
          Learn about {consumer.firstName || 'them'} and get started
        </Text>
      </div>
      <Button
        size="small"
        color="neutral-dark"
        onPress={() => {
          if (shouldShowTabs) {
            openNewLeadTab(activity.lead)
          } else {
            history.push(`/inbox/${leadId}`)
          }
          closeToast?.()
        }}
      >
        View lead
      </Button>
    </Toast>
  )
}

const newScoutLeadToast = (props: LeadReassignedActivityForLeadActivityOccurredFragment) =>
  formattedToast(<NewScoutLeadToast activity={props} />, { position: 'top-right', autoClose: 7000 })

export default newScoutLeadToast
